<template>
  <page-container class="monitoring" :title="$t('pages.titles.monitoring')">
    <v-sheet class="check-status mt-16" :class="{ 'check-status_has-error': !areAllChecksSuccessful }">
      <div v-for="checkType of checksOrder" :key="checkType" class="d-flex">
        <div class="mt-1">
          <v-icon v-if="state[checkType].status" color="success">mdi-check-circle</v-icon>
          <v-icon v-else color="error">mdi-close-circle</v-icon>
        </div>

        <div class="ml-4">
          <div class="check-status__header">
            {{ $t(`aquarius.monitoringCheckType.${checkType}`) }}
          </div>

          <div class="mt-2">
            {{ formatDateTime(state[checkType].last_check_datetime) }}
          </div>

          <div class="mt-1 text--muted">
            следующая проверка {{ formatDateTime(state[checkType].next_check_datetime) }}
          </div>
        </div>
      </div>
    </v-sheet>

    <v-data-table
      v-if="isAdmin"
      :items="logItems"
      :headers="logTableConfiguration"
      :options.sync="logTableSettings"
      :footer-props="{ itemsPerPageOptions: [15, 30, 50, 100] }"
      :loading="isPending"
      multi-sort
      :server-items-length="logItemsCount"
    >
      <template #item.datetime="{ value }">
        {{ formatDateTime(value) }}
      </template>

      <template #item.type="{ value }">
        {{ $t(`aquarius.monitoringCheckType.${value}`) }}
      </template>
    </v-data-table>
  </page-container>
</template>

<script>
  import _ from 'lodash'
  import { parseISO, format } from 'date-fns'
  import { objectFromKeys } from 'geoportal/src/utils/func'
  import { beforeDestroy } from 'geoportal/src/utils/vue'
  import { TaskTimer } from 'geoportal/src/utils/TaskTimer'
  import PendingTasksManagerMixin, { handlePendingTask } from 'geoportal/src/mixins/PendingTasksManagerMixin'
  import { AccountType, MonitoringCheckType } from '@/enums/aquarius'
  import { tableSettingsToRequestParameters } from '@/utils/tables'

  const checksOrder = [
    MonitoringCheckType.HEALTHCHECK,
    MonitoringCheckType.PLOT_CREATION,
    MonitoringCheckType.RTS_HEALTHCHECK,
    MonitoringCheckType.RTS_AUTHORIZATION
  ]
  const POLLING_TIMEOUT = 30_000
  const DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm'

  const logTableConfiguration = [
    { value: 'datetime', text: 'Дата', width: 150 },
    { value: 'type', text: 'Тип проверки', width: 300 },
    { value: 'log', text: 'Лог', sortable: false }
  ]
  const logTableSettings = {
    page: 1,
    itemsPerPage: 15,
    multiSort: true,
    sortBy: ['datetime'],
    sortDesc: [true]
  }

  export default {
    name: 'MonitoringView',
    mixins: [PendingTasksManagerMixin],

    data() {
      return {
        checksOrder,

        state: objectFromKeys(Object.values(MonitoringCheckType), () => ({})),

        logTableConfiguration,
        logTableSettings: _.cloneDeep(logTableSettings),
        logItems: [],
        logItemsCount: 0
      }
    },

    computed: {
      areAllChecksSuccessful() {
        return Object.values(this.state).every(({ status }) => status === true)
      },

      isAdmin() {
        return this.$auth.user.account_type === AccountType.ADMIN
      }
    },

    watch: {
      logTableSettings: 'fetchLogItems'
    },

    created() {
      const timer = new TaskTimer(this.updateState, POLLING_TIMEOUT, true)
      beforeDestroy(this, () => timer.clear())
    },

    methods: {
      formatDateTime(value) {
        if (!value) {
          return
        }

        const parsedDateTime = parseISO(value)
        return format(parsedDateTime, DATE_TIME_FORMAT)
      },

      async updateState() {
        const result = await this.$api.backend.monitoring.get_state()
        this.state = _.keyBy(result, 'type')
      },

      @handlePendingTask()
      async fetchLogItems() {
        const requestData = tableSettingsToRequestParameters(this.logTableSettings)
        const result = await this.$api.backend.monitoring.get_log(requestData)
        this.logItemsCount = result.count
        this.logItems = result.items
      }
    }
  }
</script>

<style lang="scss" scoped>
  .monitoring {
    .check-status {
      display: grid;
      grid-template: repeat(2, auto) / repeat(2, auto);
      gap: 48px 72px;
      margin-left: 56px;
      margin-right: 56px;
      padding: 56px 116px;
      background: #f4fbf2;
      box-shadow: 0 0 50px -2px #66c84e;

      &.check-status_has-error {
        background: #fdf0f0;
        box-shadow: 0 0 50px -2px #eb5757;
      }

      .check-status__header {
        font-size: 24px;
        line-height: 28px;
      }
    }

    .v-data-table {
      margin-top: 80px;
    }
  }
</style>
