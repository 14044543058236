export class TaskTimer {
  constructor(cb, timeout, immediate = false) {
    this.cb = cb
    this.timeout = timeout
    this._timerId = null
    this._isPending = false

    immediate ? this._callCallback() : this._startTimer()
  }

  get isPending() {
    return this._isPending
  }

  _startTimer() {
    this._timerId = setTimeout(() => this._callCallback(), this.timeout)
  }

  async _callCallback() {
    this._isPending = true
    let result

    try {
      result = await this.cb()
    } finally {
      this._isPending = false

      // if the result is a truthy value, then the timer will be stopped
      if (!result) {
        this._startTimer()
      }
    }
  }

  // immediately invokes callback
  async flush() {
    this.clear()
    await this._callCallback()
  }

  clear() {
    clearTimeout(this._timerId)
  }
}
